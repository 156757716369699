<section class="navigation-menu">
  <div class="box" [ngClass]="{ 'is-visible': isMenuOpen }">
    <div class="box-content">
      <a class="box-content__contact" href="tel:020268989" rel="noopener noreferrer">
        <i class="fas fa-phone-volume"></i><span>{{ 'Navigation.Contact Sales' | translate }}</span>
      </a>
      <div class="box-content__login desktop">
        <a
          href="https://auth.flowaccount.com/{{ translate.currentLang }}"
          rel="noopener noreferrer"
          class="box-content__login--layout"
          (click)="getTrackEvent('102', 'login navbar')"
        >
          <img src="https://flowaccountcdn.com/new_landing/icon/login.svg" alt="login" />
          <span>{{ 'Navigation.Log in' | translate }}</span>
        </a>
      </div>
      <div class="box-content__language">
        <a (click)="switchLanguage()" class="box-content__language--switch">
          <img
            [src]="
              translate.currentLang === 'th'
                ? 'https://flowaccountcdn.com/assets/image/language-en.png'
                : 'https://flowaccountcdn.com/assets/image/language-th.png'
            "
            alt="{{ translate.currentLang }} icon"
          />
        </a>
      </div>
    </div>

    <div class="box-menu">
      <div class="box-menu__flowaccount">
        <a
          [routerLink]="[translate.currentLang === 'th' ? '/' : translate.currentLang]"
          routerLinkActive="current"
          (click)="getTrackEvent('102', 'logo navbar'); closeMenuAndPanel()"
        >
          <img
            src="https://flowaccountcdn.com/new_landing/image/flowaccount_logo_banner.svg"
            class="box-menu__flowaccount--logo"
            alt="FlowAccount"
          />
        </a>
      </div>

      <div class="box-menu__container desktop">
        <div class="box-menu__container--category">
          <div *ngFor="let category of categories" class="category-item {{ category.class }}">
            <a
              [routerLink]="
                category.route
                  ? [translate.currentLang === 'th' ? category.route : translate.currentLang + category.route]
                  : null
              "
              routerLinkActive="current"
            >
              <div class="category-item__list">
                <span>{{ category.label | translate }}</span>
                <ng-container *ngIf="category.items">
                  <i class="fas fa-angle-down"></i>
                  <i class="fas fa-angle-up"></i>
                </ng-container>
              </div>

              <div *ngIf="category.items" class="category-item__flyout">
                <div class="flyout-main">
                  <div class="flyout-main__arrow"></div>
                  <div class="flyout-main__list">
                    <ng-container *ngFor="let item of category.items">
                      <ng-container *ngIf="item.route && !item.list">
                        <a
                          class="flyout-main__list--item"
                          [routerLink]="[
                            translate.currentLang === 'th' ? item.route : translate.currentLang + item.route
                          ]"
                          routerLinkActive="current"
                          [ngClass]="{ 'color-gray': item.isSpecialLink }"
                        >
                          <div class="item-image" *ngIf="item.image"
                            ><img [src]="item.image" class="item-image__layout" alt="{{ item.heading | translate }}"
                          /></div>
                          <div class="item-icon" *ngIf="item.icon"><i class="{{ item.icon }}"></i></div>
                          <div class="item-text">
                            <div class="item-text__heading"
                              ><span>{{ item.heading | translate }}</span></div
                            >
                            <div class="item-text__info" *ngIf="item.info"
                              ><span>{{ item.info | translate }}</span></div
                            >
                          </div>
                        </a>
                      </ng-container>

                      <ng-container *ngIf="item.href">
                        <a
                          class="flyout-main__list--item"
                          [href]="item.href"
                          rel="noopener noreferrer"
                          target="_blank"
                          [ngClass]="{ 'color-gray': item.isSpecialLink }"
                        >
                          <div class="item-image" *ngIf="item.image"
                            ><img [src]="item.image" class="item-image__layout" alt="{{ item.heading | translate }}"
                          /></div>
                          <div class="item-icon" *ngIf="item.icon"><i class="{{ item.icon }}"></i></div>
                          <div class="item-text">
                            <div class="item-text__heading"
                              ><span>{{ item.heading | translate }}</span></div
                            >
                            <div class="item-text__info" *ngIf="item.info"
                              ><span>{{ item.info | translate }}</span></div
                            >
                          </div>
                        </a>
                      </ng-container>

                      <ng-container *ngIf="item.list">
                        <div class="flyout-main__list--item submain-fake">
                          <div class="item-image" *ngIf="item.image"
                            ><img [src]="item.image" class="item-image__layout" alt="{{ item.heading | translate }}"
                          /></div>
                          <div class="item-icon" *ngIf="item.icon"><i class="{{ item.icon }}"></i></div>
                          <div class="item-text">
                            <div class="item-text__heading"
                              ><span>{{ item.heading | translate }}</span></div
                            >
                            <div class="item-text__info" *ngIf="item.info"
                              ><span>{{ item.info | translate }}</span></div
                            >
                          </div>
                          <div class="item-arrows-right"
                            ><img
                              class="item-arrows-right__layout"
                              src="https://flowaccountcdn.com/new_landing/icon/chevron-right.svg"
                              alt="chevron-right"
                          /></div>
                          <div class="item-submain">
                            <div class="item-submain__list">
                              <ng-container *ngFor="let list2 of item.list">
                                <a
                                  class="item-submain__list--element"
                                  [routerLink]="[
                                    translate.currentLang === 'th' ? list2.route : translate.currentLang + list2.route
                                  ]"
                                  routerLinkActive="current"
                                >
                                  <div class="element-image" *ngIf="list2.image"
                                    ><img
                                      [src]="list2.image"
                                      class="element-image__layout"
                                      alt="{{ list2.heading | translate }}"
                                  /></div>
                                  <div class="element-icon" *ngIf="list2.icon"><i class="{{ list2.icon }}"></i></div>
                                  <div class="element-text">
                                    <div class="element-text__heading"
                                      ><span>{{ list2.heading | translate }}</span></div
                                    >
                                    <div class="element-text__info" *ngIf="item.list2"
                                      ><span>{{ list2.info | translate }}</span></div
                                    >
                                  </div>
                                </a>
                              </ng-container>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>

        <div class="box-menu__container--btn">
          <a
            class="btn-layout"
            rel="noopener noreferrer"
            href="https://auth.flowaccount.com/{{ translate.currentLang }}/Account/Register"
            (click)="getTrackEvent('102', 'signup navbar')"
            ><span>{{ 'Navigation.Sign up' | translate }}</span></a
          >
          <a
            class="btn-layout booking-demo"
            rel="noopener noreferrer"
            href="https://form.flowaccount.com/register-seminar-business-owner-beginner"
            (click)="getTrackEvent('102', 'demo schedule navbar')"
            ><span>{{ 'Navigation.Demo' | translate }}</span></a
          >
        </div>
      </div>

      <div class="box-menu__container mobile">
        <button
          type="button"
          class="box-menu__container--menutoggle"
          [ngClass]="{ 'menu-open': isMenuOpen }"
          (click)="toggleMenu()"
        >
          <span class="menutoggle-icon" aria-hidden="true">
            <span class="menutoggle-icon__top"></span>
            <span class="menutoggle-icon__bottom"></span>
            <span class="menutoggle-icon__clockwise"></span>
            <span class="menutoggle-icon__counterwise"></span>
          </span>
        </button>
      </div>

      <div class="box-menu__muipaper mobile" [ngClass]="{ 'is-open': isMenuOpen }">
        <div *ngFor="let category of categories; let i = index" class="box-menu__muipaper--svelte {{ category.class }}">
          <a
            [routerLink]="
              category.route
                ? [translate.currentLang === 'th' ? category.route : translate.currentLang + category.route]
                : null
            "
            routerLinkActive="current"
            (click)="category.route ? closeMenuAndPanel() : togglePanel(i)"
          >
            <div class="svelte-header" [ngClass]="{ 'is-open': openPanelIndex === i }">
              <span>{{ category.label | translate }}</span>
              <ng-container *ngIf="category.items">
                <i class="fas fa-angle-down"></i>
                <i class="fas fa-angle-up"></i>
              </ng-container>
            </div>

            <div class="svelte-dropdown" (click)="togglePanel(i)" [ngClass]="{ 'is-open': openPanelIndex === i }">
              <ng-container *ngFor="let item of category.items; let index = index">
                <ng-container *ngIf="item.route && !item.list">
                  <a
                    [routerLink]="[translate.currentLang === 'th' ? item.route : translate.currentLang + item.route]"
                    class="svelte-dropdown__bar"
                    [ngClass]="{ 'color-gray': item.isSpecialLink }"
                    (click)="closeMenuAndPanel()"
                  >
                    <div class="svelte-dropdown__bar--image" *ngIf="item.image">
                      <img [src]="item.image" class="image-layout" alt="{{ item.heading | translate }}" />
                    </div>
                    <div class="svelte-dropdown__bar--icon" *ngIf="item.icon">
                      <i class="{{ item.icon }}"></i>
                    </div>
                    <div class="svelte-dropdown__bar--text">
                      <span class="text-title">{{ item.heading | translate }}</span>
                      <div class="text-detail" *ngIf="item.info">
                        <span>{{ item.info | translate }}</span>
                      </div>
                    </div>
                  </a>
                </ng-container>

                <ng-container *ngIf="item.href">
                  <a
                    class="svelte-dropdown__bar"
                    [href]="item.href"
                    rel="noopener noreferrer"
                    target="_blank"
                    [ngClass]="{ 'color-gray': item.isSpecialLink }"
                    (click)="closeMenuAndPanel()"
                  >
                    <div class="svelte-dropdown__bar--image" *ngIf="item.image">
                      <img [src]="item.image" class="image-layout" alt="{{ item.heading | translate }}" />
                    </div>
                    <div class="svelte-dropdown__bar--icon" *ngIf="item.icon">
                      <i class="{{ item.icon }}"></i>
                    </div>
                    <div class="svelte-dropdown__bar--text">
                      <span class="text-title">{{ item.heading | translate }}</span>
                      <div class="text-detail" *ngIf="item.info">
                        <span>{{ item.info | translate }}</span>
                      </div>
                    </div>
                  </a>
                </ng-container>

                <ng-container *ngIf="item.list">
                  <div
                    class="svelte-dropdown__bar submain-fake-mobile"
                    (click)="toggleSubPanel(index, $event)"
                    [ngClass]="{ 'is-open': openSubPanelIndex === index }"
                  >
                    <div class="svelte-dropdown__bar--image" *ngIf="item.image">
                      <img [src]="item.image" class="item-image__layout" alt="{{ item.heading | translate }}" />
                    </div>
                    <div class="svelte-dropdown__bar--icon" *ngIf="item.icon">
                      <i class="{{ item.icon }}"></i>
                    </div>
                    <div class="svelte-dropdown__bar--text">
                      <div class="text-title">
                        <span>{{ item.heading | translate }}</span>
                      </div>
                      <div class="text-info" *ngIf="item.info">
                        <span>{{ item.info | translate }}</span>
                      </div>
                    </div>
                    <div class="svelte-dropdown__bar--arrows">
                      <ng-container *ngIf="category.items">
                        <i class="fas fa-angle-down"></i>
                        <i class="fas fa-angle-up"></i>
                      </ng-container>
                    </div>
                  </div>

                  <div class="svelte-dropdown__listmenu" [ngClass]="{ 'is-open': openSubPanelIndex === index }">
                    <ng-container *ngFor="let list2 of item.list">
                      <a
                        class="listmenu-submain"
                        [routerLink]="[
                          translate.currentLang === 'th' ? list2.route : translate.currentLang + list2.route
                        ]"
                        routerLinkActive="current"
                        (click)="closeMenuAndPanel()"
                      >
                        <div class="listmenu-submain__image" *ngIf="list2.image">
                          <img [src]="list2.image" class="image-layout" alt="{{ list2.heading | translate }}" />
                        </div>
                        <div class="listmenu-submain__icon" *ngIf="list2.icon">
                          <i class="{{ list2.icon }}"></i>
                        </div>
                        <div class="listmenu-submain__text">
                          <span class="text-title">{{ list2.heading | translate }}</span>
                          <div class="text-detail" *ngIf="list2.info">
                            <span>{{ list2.info | translate }}</span>
                          </div>
                        </div>
                      </a>
                    </ng-container>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </a>
        </div>
        <div class="box-menu__muipaper--btn">
          <div class="btn-register">
            <a
              class="btn-register__layout"
              rel="noopener noreferrer"
              href="https://auth.flowaccount.com/{{ translate.currentLang }}/Account/Register"
              (click)="getTrackEvent('102', 'signup navbar')"
              ><span>{{ 'Navigation.Sign up' | translate }}</span></a
            >
            <a
              class="btn-register__layout booking-demo"
              rel="noopener noreferrer"
              href="https://form.flowaccount.com/register-seminar-business-owner-beginner"
              (click)="getTrackEvent('102', 'demo schedule navbar')"
              ><span>{{ 'Navigation.Demo' | translate }}</span></a
            >
          </div>

          <div class="btn-login">
            <a
              href="https://auth.flowaccount.com/{{ translate.currentLang }}"
              rel="noopener noreferrer"
              class="btn-login__layout"
              (click)="getTrackEvent('102', 'login navbar')"
            >
              <img src="https://flowaccountcdn.com/new_landing/icon/login.svg" alt="login" />
              <span>{{ 'Navigation.Log in' | translate }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<div [ngClass]="{ 'is-visible': isMenuOpen }" (click)="closeMenuAndPanel()" class="backdrop" aria-hidden="true"></div>
