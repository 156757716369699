import { BlogService } from './services/blog.service'
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { AppComponent } from './app.component'
import { AppRoutingModule } from './app-routing.module'
import { CoreModule } from './core.module'
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClientXsrfModule } from '@angular/common/http'
import { TransferHttpCacheModule } from '@nguniversal/common'
import { APP_BASE_HREF, DatePipe } from '@angular/common'
import { environment } from '../environments/environment'
import {
  CustomTranslateModule,
  appStateReducer,
  NavigationModule,
  PipesModule,
  GROWTHBOOK_CONFIG,
  GrowthBookConfig,
  GrowthBookService,
  ReferrerService,
  SEOService,
  SharedService,
  SignupFlowService,
} from '@flowaccount/landing'
import { CookieService } from 'ngx-cookie-service'
import { NotfoundComponent } from './components/notfound/notfound.component'
import { StoreModule } from '@ngrx/store'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RecaptchaModule } from 'ng-recaptcha'
import { SitemapComponent } from './components/sitemap/sitemap.component'
import { ScullyLibModule } from '@scullyio/ng-lib'
import { ServiceWorkerModule } from '@angular/service-worker'
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'
// lazy load image
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2'
import { FaqService } from './services/faq.service'
import { AuthInterceptor } from './services/auth.interceptor'
import { SocialChatComponent } from './components/social-chat/social-chat.component'
import { DeviceDetectorService } from 'ngx-device-detector'
import { EventTrackerService } from './services/event-tracker.service'
import { SeminarTrackingService } from './services/seminar-tracking.service'
import { CsrfInterceptor } from './services/csrf.interceptor'
import { VisitorService } from './services/visitor.service'
import { TrafficSourceService } from './services/traffic-source.service'
import { provideClientHydration } from '@angular/platform-browser'
import { NavbarMenuComponent } from './components/navigation-menu/navigation-menu.component'
import { DownloadAppBannerComponent } from './components/download-app-banner/download-app-banner.component'

export function provideSwal() {
  return import('sweetalert2/dist/sweetalert2.js') // instead of import('sweetalert2')
}

@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'flowaccount-classic' }),
    BrowserAnimationsModule,
    // BrowserTransferStateModule, //=> deprecate
    StoreModule.forRoot({ appState: appStateReducer }),
    AppRoutingModule,
    CustomTranslateModule.forRoot(),
    CoreModule.forRoot(),
    TransferHttpCacheModule,
    HttpClientModule,
    PipesModule,
    NavigationModule,
    RecaptchaModule,
    ScullyLibModule,
    SweetAlert2Module.forRoot({ provideSwal }),
    ServiceWorkerModule.register('landing-worker.js', {
      enabled: environment.production,
    }),
    NavigationModule,
    NgIdleKeepaliveModule.forRoot(),
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN',
    }),
  ],
  declarations: [
    AppComponent,
    NotfoundComponent,
    SitemapComponent,
    SocialChatComponent,
    DownloadAppBannerComponent,
    NavbarMenuComponent,
  ],
  providers: [
    BlogService,
    ReferrerService,
    SharedService,
    CookieService,
    FaqService,
    {
      provide: APP_BASE_HREF,
      useValue: environment.baseHref,
    },
    SEOService,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CsrfInterceptor, multi: true },
    {
      provide: GROWTHBOOK_CONFIG,
      useFactory: () =>
        ({
          apiHost: environment.growthBookEndpoint.apiHost,
          clientKey: environment.growthBookEndpoint.clientKey,
          enableDevMode: environment.growthBookEndpoint.enableDevMode,
        } as GrowthBookConfig),
    },
    GrowthBookService,
    DeviceDetectorService,
    VisitorService,
    EventTrackerService,
    SeminarTrackingService,
    TrafficSourceService,
    provideClientHydration(),
    SignupFlowService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
